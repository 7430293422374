import React, {useEffect} from "react";
import Lobby from "../lobby/lobby";
import "./host-page.css"
import Start from "../start/start";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Tutorial from "../../shared/tutorial/tutorial";
import MusicControl from "../../../components/music-control/music-control";
import useWebSocket from "react-use-websocket";
import Playing from "../playing/playing";
import Answer from "../../shared/answer/answer";
import SippingTime from "../sipping-time/sipping-time";
import Winner from "../winner/winner";
import Scoreboard from "../scoreboard/scoreboard";
import NoConnection from "../../shared/no-connection/no-connection";
import {EventType} from "../../../events";
import {ToastService} from "../../../services/toaster.service";
import {BowbEvent} from "../../../../../common/models";
import WswcWrapped from "../wswc-wrapped/wswc-wrapped";

export default function HostPage({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const {lastJsonMessage} = webSocket;
    const {currentState, setCurrentState} = useGameState();

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.JOINED_LOBBY_SUCCESS) {
            ToastService.success(event?.data.message);

            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)
            setCurrentState(prevState => ({
                ...prevState,
                host: {
                    ...prevState.host,
                    players: playerList
                }
            }))
        }
    }, [lastJsonMessage])

    if (!webSocket.readyState || webSocket.readyState === 3)
        return <NoConnection/>

    switch (currentState.host.state) {
        case "Start":
            return <><Start webSocket={webSocket}/> <MusicControl state={'lobby'}/> </>;
        case 'Lobby':
            return <><Lobby webSocket={webSocket}/> <MusicControl state={'lobby'}/> </>;
        case 'Wrapped':
            return <><WswcWrapped webSocket={webSocket}/> <MusicControl state={'lobby'}/> </>;
        case 'Tutorial':
            return <><Tutorial webSocket={webSocket} host={true}/> <MusicControl state={'playing'}/> </>;
        case 'Playing':
            return <><Playing webSocket={webSocket}/> <MusicControl state={'playing'}/> </>;
        case 'Answer':
            return <><Answer webSocket={webSocket} host={true}/> <MusicControl state={'playing'}/> </>;
        case 'SippingTime':
            return <><SippingTime webSocket={webSocket}/> <MusicControl state={'playing'}/> </>;
        case 'Winner':
            return <><Winner webSocket={webSocket}/> <MusicControl state={'playing'}/> </>;
        case 'Scoreboard':
            return <><Scoreboard webSocket={webSocket}/> <MusicControl state={'playing'}/> </>;
        default:
            return <div>Error: Invalid game state</div>;
    }
}
