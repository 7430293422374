export const EventType = {
    // USER EVENTS
    USER_EVENT: 'USER_EVENT',
    USER_JOIN: 'USER_JOIN_EVENT',
    USER_REJOIN: "USER_REJOIN_EVENT",

    // LOBBY EVENTS
    LOBBY_EVENT: 'LOBBY_EVENT',
    CREATE_LOBBY: 'CREATE_LOBBY_EVENT',
    OPEN_LOBBY: 'OPEN_LOBBY_EVENT',
    CLOSE_LOBBY: 'CLOSE_LOBBY_EVENT',
    JOIN_LOBBY: 'JOIN_LOBBY_EVENT',
    REJOIN_LOBBY: "REJOIN_LOBBY_EVENT",
    LEFT_LOBBY: 'LEFT_LOBBY_EVENT',
    JOINED_LOBBY_SUCCESS: "JOINED_LOBBY_SUCCESS_EVENT",
    JOINED_LOBBY_FAILURE: "JOINED_LOBBY_FAILURE_EVENT",
    TO_LOBBY: "TO_LOBBY_EVENT",

    // GAME EVENTS
    GAME_EVENT: 'GAME_EVENT',
    START_GAME: 'START_GAME_EVENT',
    NEXT_QUOTE: 'NEXT_QUOTE_EVENT',
    NEXT_QUOTE_FOUND: 'NEXT_QUOTE_FOUND_EVENT',
    CHOOSE_ANSWER: "CHOOSE_ANSWER_EVENT",
    ANSWER_CHOSEN: "ANSWER_CHOSEN_EVENT",
    SHOW_ANSWER: "SHOW_ANSWER_EVENT",
    SIPPED: "SIPPED_EVENT",
    TIME_TO_SIP: "TIME_TO_SIP_EVENT",
    EVERYONE_SIPPED: "EVERYONE_SIPPED_EVENT",
    TIME_TO_SIP_NOW: "TIME_TO_SIP_NOW_EVENT",
    NO_SIPS: "NO_SIPS_EVENT",
    ANNOUNCE_WINNER: "ANNOUNCE_WINNER_EVENT",
    WAIT_ONE_ROUND: "WAIT_ONE_ROUND_EVENT",
    PLAY_AGAIN: "PLAY_AGAIN_EVENT",
    END_GAME: "END_GAME_EVENT",
    NO_QUOTES_LEFT: "NO_QUOTES_LEFT_EVENT",

    // SETTINGS EVENTS
    SETTINGS_EVENT: 'SETTINGS_EVENT',
    EXPORT_USED_QUOTES: "EXPORT_USED_QUOTES_EVENT",
    IMPORT_USED_QUOTES: "IMPORT_USED_QUOTES_EVENT",
    SKIP_QUOTES_PERCENTAGE: "SKIP_QUOTES_PERCENTAGE_EVENT", // Will skip a percentage of quotes per user.
    SHOW_SETTINGS: "SHOW_SETTINGS_EVENT",
    SET_MAX_ROUNDS: "SET_MAX_ROUNDS_EVENT"
}