import React, {FC} from "react";
import "./wswc-wrapped-overview.css";
import {GeneralScores} from "../../../../../common/models";
import Textbox from "../../../components/textbox/textbox";
import CommonButton from "../../../components/button/button";
import FadeTransition from "../../../components/fade-element/FadeTransition";

interface WswcWrappedOverviewProps {
    scores: GeneralScores;
    onRestart: () => void;
    toLobby: () => void;
}

const WswcWrappedOverview: FC<WswcWrappedOverviewProps> = ({scores, onRestart, toLobby}) => {
    return (
        <div className="wrapped-overview-container">
            <h1 className="overview-title">WSWC Wrapped - Summary</h1>

            <div className="overview-grid">
                <div className="overview-card">
                    <h2 className="overview-title">Connoisseur</h2>
                    <Textbox text={scores.connoisseur.name}/>
                    <p className="overview-score">{scores.connoisseur.score} correct answers</p>
                </div>

                <div className="overview-card">
                    <h2 className="overview-title">Drunkard</h2>
                    <Textbox text={scores.clueless.name}/>
                    <p className="overview-score">{scores.clueless.score} sips taken</p>
                </div>

                <div className="overview-card">
                    <h2 className="overview-title">Quickdraw</h2>
                    <Textbox text={scores.quickdraw.name}/>
                    <p className="overview-score">{scores.quickdraw.score.toFixed(2)} sec avg</p>
                </div>

                <div className="overview-card">
                    <h2 className="overview-title">Most Misjudged</h2>
                    <img className={"overview-image"}
                         src={`${process.env.PUBLIC_URL}/assets/img/gang-members/${scores.unknownAuthor.name}.jpg`}
                         alt="Unknown author content"/>
                </div>

                <div className="overview-card">
                    <h2 className="overview-title">Most Recognized</h2>
                    <img className={"overview-image"}
                         src={`${process.env.PUBLIC_URL}/assets/img/gang-members/${scores.wellKnownAuthor.name}.jpg`}
                         alt="infamous author content"/>
                </div>

                <FadeTransition fadeInDelay={3000} startAlreadyRendered={true}>
                    <div className="overview-card">
                        <div className="overview-buttons">
                            <CommonButton text={"Again?"} action={onRestart}/>
                            <CommonButton text={"< Lobby"} action={toLobby}/>
                        </div>
                    </div>
                </FadeTransition>
            </div>
        </div>
    );
};

export default WswcWrappedOverview;
