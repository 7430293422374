import React, {FC, useEffect, useState} from 'react';
import "./winner.css";
import CommonBob from "../../../components/bob/bob";
import useWebSocket from "react-use-websocket";
import Textbox from "../../../components/textbox/textbox";
import AudioService from "../../../services/audio.service";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonButton from "../../../components/button/button";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

interface WinnerScreenProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const Winner: FC<WinnerScreenProperties> = () => {
    const [startFade, setStartFade] = useState(false);
    const [startFadeConfetti, setStartFadeConfetti] = useState(false);
    const {currentState, setCurrentState} = useGameState();

    useEffect(() => {
        if (!startFade && !startFadeConfetti)
            AudioService.playSpecificEffect("fnaf-yay").then(r => (r))

        setTimeout(() => {
            setStartFadeConfetti(true);
        }, 3000);
    });

    const handleContinue = () => {
        setStartFade(true);

        setTimeout(() => {
            setCurrentState(prevState => ({
                ...prevState,
                lobby: {...prevState.lobby, currentRound: prevState.lobby.currentRound + 1},
                host: {...prevState.host, state: "Scoreboard"},
                player: {...prevState.player, state: "Scoreboard"}
            }));
        }, STANDARD_TRANSITION_DELAY);
    }

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            <div className={"everything-container"}>
                <div className={"borp"}>
                    <Textbox text={`The winner is...`}></Textbox>
                    <img className={"talking-stick"}
                         src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                         alt={"talking shtick"}/>
                    <CommonBob></CommonBob>
                </div>

                <div className={"win-container"}>
                    <Textbox text={`${currentState.lobby.winner}!`}></Textbox>
                </div>
                <div className={"choose-container"}>
                    <Textbox text={`${currentState.lobby.winner} may now choose 3 people who have to drink.`}></Textbox>
                </div>

                <CommonButton text={"Continue"} action={handleContinue}/>

                <FadeTransition startFadeOut={startFadeConfetti}>
                    <img className={"fnaf-confetti"} src={`${process.env.PUBLIC_URL}/assets/img/fnaf-confetti.gif`}
                         alt={"fnaf confetti"}/>
                </FadeTransition>
            </div>
        </FadeTransition>
    );
};

export default Winner;
