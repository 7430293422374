import React, {FC, useEffect, useState} from 'react';
import "./scoreboard.css";
import CommonBob from "../../../components/bob/bob";
import useWebSocket from "react-use-websocket";
import Textbox from "../../../components/textbox/textbox";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../../../common/models";
import {EventType} from "../../../events";
import ScoreboardTable from "../../../components/player-table/ScoreboardTable";
import CommonButton from "../../../components/button/button";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

interface ScoreboardProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

const Scoreboard: FC<ScoreboardProperties> = ({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) => {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
        if (!validLobby) return;

        if (event?.subType === EventType.TO_LOBBY) {
            setStartFade(true);
            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {...prevState.host, state: "Lobby"},
                    lobby: {...prevState.lobby, maxRounds: event.data.max_rounds}
                }));
            }, STANDARD_TRANSITION_DELAY);
        }
    }, [lastJsonMessage]);

    const handleBackToLobby = () => {
        sendJsonMessage(
            {
                type: EventType.GAME_EVENT,
                subType: EventType.PLAY_AGAIN,
                data: {lobby_code: currentState.lobby.code}
            });
    }

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            <div className="scoreboard-container">

                <div className={"borp"}>
                    <Textbox text={`Thanks for playing!`}></Textbox>
                    <img className={"talking-stick"}
                         src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                         alt={"talking shtick"}/>
                    <CommonBob></CommonBob>
                    <span className={"back-to-lobby-button"}>
                        <CommonButton text={"Lobby >"} action={handleBackToLobby}></CommonButton>
                        </span>
                </div>

                <ScoreboardTable lobby={currentState.lobby}/>
            </div>
        </FadeTransition>
    );
};

export default Scoreboard;
