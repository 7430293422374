import {Lobby, QuoteWithAuthor} from "../../../../common/models";
import {AuthorOption} from "../../../../common/types";

export type GameStateValue =
    "Start"
    | "Lobby"
    | "Wrapped"
    | "Waiting"
    | "Tutorial"
    | "Playing"
    | "Answer"
    | "SippingTime"
    | "Winner"
    | "Scoreboard";

export class GameState {
    host: { state: GameStateValue, players: string[] } = {
        state: "Start",
        players: []
    };
    player: { state: GameStateValue, username: string, rejoined: boolean } = {
        username: "",
        state: "Start",
        rejoined: false
    };
    lobby: Lobby = {
        code: "",
        currentRound: 0,
        maxRounds: 0,
        winner: "",
        totalUsedQuotes: 0,
        score: {incorrectGuesses: {}, correctGuesses: {}, playerScores: {}}
    }
    currentQuote: {
        question: string;
        quoteData: QuoteWithAuthor,
        options: AuthorOption[];
        playersTotal: number,
        playersToAnswer: number,
        playersToSip: string[]
    } = {
        question: "Who spoke so eloquently?",
        quoteData: {id: 0, quoteText: "Ik eet voeten", author: "Soeb", imageName: ""},
        options: ["Bino", "Soeb", "Arvit", "Jordt"],
        playersTotal: 0,
        playersToAnswer: 0,
        playersToSip: [],
    }
}
