import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./waiting.css";
import Textbox from "../../../components/textbox/textbox";
import Toaster from "../../../services/toaster.service";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../../../common/models";
import {EventType} from "../../../events";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

export default function Waiting({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.START_GAME) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({...prevState, player: {...prevState.player, state: 'Tutorial'}}))
            }, STANDARD_TRANSITION_DELAY);
        } else if (event?.subType === EventType.NEXT_QUOTE_FOUND) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: event.data.currentQuote,
                    host: {...prevState.host, state: "Playing"},
                    player: {...prevState.player, state: "Playing", rejoined: false}
                }));
            }, STANDARD_TRANSITION_DELAY);
        } else if (event?.subType === EventType.USER_REJOIN) {
            setCurrentState(prevState => ({
                ...prevState,
                player: {...prevState.player, rejoined: true}
            }));
        }
    }, [lastJsonMessage]);

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            <div className={"player-wait-holder"}>
                <Textbox text={`Hi ${currentState.player.username}!`}/>
                {currentState.player.rejoined
                    ? <Textbox text={`Waiting for the current round to finish...`}/>
                    : <Textbox text={`Waiting for the host to start the game...`}/>
                }
                <img className={"loading-gif"}
                     src={"./assets/img/bob/bob-Hopping.gif"}
                     alt={"Bob hopping indefinitely"}/>

                <Toaster/>
            </div>
        </FadeTransition>
    );
}
