import {GeneralScores, Lobby} from "../../../common/models";
import {AuthorOption} from "../../../common/types";


export default class WswcWrappedService {
    public static calculateGeneralScores(lobby: Lobby): GeneralScores {
        if (!lobby) throw new Error("Invalid lobby data.");

        let connoisseur: { name: string; score: number } = { name: "", score: 0 };
        let clueless: { name: string; score: number } = { name: "", score: 0 };
        let quickdraw: { name: string; score: number } = { name: "", score: Infinity };
        let unknownAuthor: { name: AuthorOption; score: number } = { name: "Invalid", score: 0 };
        let wellKnownAuthor: { name: AuthorOption; score: number } = { name: "Invalid", score: 0 };
        let totalQuotes: number = lobby.totalUsedQuotes;

        // Tracking authors' guess stats
        let mostCorrectAuthor: { name: AuthorOption; percentage: number } = { name: "Invalid", percentage: 0 };
        let mostMissedAuthor: { name: AuthorOption; percentage: number } = { name: "Invalid", percentage: 0 };

        for (const [username, score] of Object.entries(lobby.score.playerScores)) {
            // Connoisseur (most correct answers)
            if (score.totalCorrectScore > connoisseur.score) {
                connoisseur = { name: username, score: score.totalCorrectScore };
            }

            // Clueless (most incorrect answers)
            if (score.totalIncorrectScore > clueless.score) {
                clueless = { name: username, score: score.totalIncorrectScore };
            }

            // Quickdraw (fastest average answer time)
            const totalAnswers = score.totalCorrectScore + score.totalIncorrectScore;
            if (totalAnswers > 0) {
                const avgTimeMillis = score.totalTimeToAnswer / totalAnswers;
                let avgTimeSeconds = round(avgTimeMillis / 1000, 2);

                if (avgTimeSeconds < quickdraw.score) {
                    quickdraw = { name: username, score: avgTimeSeconds };
                }
            }
        }

        // Author Insights
        const correctGuesses = lobby.score.correctGuesses;
        const incorrectGuesses = lobby.score.incorrectGuesses;

        for (const author in correctGuesses) {
            const correct = correctGuesses[author] || 0;
            const incorrect = incorrectGuesses[author] || 0;
            const total = correct + incorrect;

            if (total > 0) {
                const correctPercentage = (correct / total) * 100;
                const incorrectPercentage = (incorrect / total) * 100;

                if (correctPercentage > mostCorrectAuthor.percentage) {
                    mostCorrectAuthor = { name: author as AuthorOption, percentage: correctPercentage };
                }

                if (incorrectPercentage > mostMissedAuthor.percentage) {
                    mostMissedAuthor = { name: author as AuthorOption, percentage: incorrectPercentage };
                }
            }
        }

        // Assign final values for authors
        if (mostCorrectAuthor.name !== "Invalid") {
            wellKnownAuthor = { name: mostCorrectAuthor.name, score: mostCorrectAuthor.percentage };
        }

        if (mostMissedAuthor.name !== "Invalid") {
            unknownAuthor = { name: mostMissedAuthor.name, score: mostMissedAuthor.percentage };
        }

        // Handle edge cases
        if (connoisseur.name === "") connoisseur = { name: "None yet!", score: 0 };
        if (clueless.name === "") clueless = { name: "None yet!", score: 0 };
        if (quickdraw.score === Infinity) quickdraw = { name: "None yet!", score: 0 };

        return { connoisseur, clueless, quickdraw, unknownAuthor, wellKnownAuthor, totalQuotes };
    }
}

const round = (num: number, decimalPoints: number) => {
    const decimals = +('1'.padEnd(decimalPoints + 1, '0'))
    return Math.round(num * decimals) / decimals
}
