const base = "./assets/sound";

export default class AudioService {
    public static backgroundMusic = new Audio(`${base}/soundtrack/WhoShouldWeCancel_Lobby.mp3`);
    private static soundEffect = new Audio(`${base}/soundeffects/1.mp3`);

    private static previousSounds: number[] = [];

    public static async playRandomEffect(callbackWhenDone?: () => void) {
        let randomInt: number;
        do {
            randomInt = Math.floor(Math.random() * 8) + 1;
        } while (this.previousSounds.includes(randomInt));

        if (this.previousSounds.length === 5)
            this.previousSounds.shift();

        this.previousSounds.push(randomInt);
        await this.playSpecificEffect(`soundeffects/${randomInt}.mp3`, true, callbackWhenDone, 0.4);
    }

    public static async playSpecificEffect(trackName: string, muteMusic: boolean = false, callbackWhenDone?: () => void, volume: number = 0.5, loop: boolean = false) {
        if (muteMusic)
            AudioService.setMusicVolume(0);
        if (!trackName.endsWith(".mp3"))
            trackName += ".mp3";
        if (!trackName.startsWith("soundeffects/"))
            trackName = "soundeffects/" + trackName;

        this.soundEffect = new Audio(`${base}/${trackName}`);
        AudioService.soundEffect.volume = volume;
        AudioService.soundEffect.muted = false;
        AudioService.soundEffect.loop = loop;
        await AudioService.soundEffect.play();

        // If sound is still going after 8 seconds.
        let timerId = setTimeout(() => {
            if (!this.soundEffect.paused && !this.soundEffect.ended) {
                AudioService.soundEffect.muted = true;
                AudioService.soundEffect.volume = 0;
                AudioService.soundEffect.remove();
                AudioService.setMusicVolume(0.5);
            }
        }, 8000);

        // Function to set volume and handle when sound ends.
        this.soundEffect.onended = () => {
            AudioService.soundEffect.muted = true;
            AudioService.soundEffect.volume = 0;
            AudioService.soundEffect.remove();
            AudioService.setMusicVolume(0.5);
            clearTimeout(timerId);
            if (callbackWhenDone) callbackWhenDone();
        };
    }

    public static async playMusic(trackName: string) {
        await AudioService.stopMusic();

        this.backgroundMusic = new Audio(`${base}/${trackName}`);

        AudioService.backgroundMusic.volume = 0.5;
        AudioService.backgroundMusic.muted = false;
        AudioService.backgroundMusic.loop = true;
        await AudioService.backgroundMusic.play();
    }

    public static async stopMusic() {
        if (!this.backgroundMusic.paused) {
            this.backgroundMusic.pause();
            this.backgroundMusic.currentTime = 0;
        }
        this.backgroundMusic.muted = true;
        this.backgroundMusic.remove();
    }

    public static mute(mute: boolean) {
        AudioService.backgroundMusic.muted = mute;
        AudioService.soundEffect.muted = mute;
    }

    public static setMusicVolume(volume: number) {
        this.backgroundMusic.volume = volume;
        AudioService.soundEffect.volume = volume;
    }
}
