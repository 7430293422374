import React, {ReactNode, useEffect, useState} from "react";

interface FadeTransitionProps {
    children: ReactNode;
    startFadeIn?: boolean;
    startFadeOut?: boolean;
    fadeInDelay?: number;
    fadeOutDelay?: number;
    startAlreadyRendered?: boolean;
}

const TIME_TO_FADE = 1000;

export default function FadeTransition({
                                           children,
                                           fadeOutDelay = 0,
                                           fadeInDelay = 100,
                                           startFadeIn = true,
                                           startFadeOut = false,
                                           startAlreadyRendered = false,
                                       }: FadeTransitionProps) {
    const [visible, setVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        let fadeInTimeout: NodeJS.Timeout | null = null;
        let fadeOutTimeout: NodeJS.Timeout | null = null;
        let removeTimeout: NodeJS.Timeout | null = null;

        if (startAlreadyRendered)
            setShouldRender(true);

        if (!startFadeOut && startFadeIn) {
            fadeInTimeout = setTimeout(() => {
                setShouldRender(true);
            }, fadeInDelay - 90);
            fadeInTimeout = setTimeout(() => {
                setVisible(true);
            }, fadeInDelay);
        }

        if (startFadeOut) {
            fadeOutTimeout = setTimeout(() => setVisible(false), fadeOutDelay);
            removeTimeout = setTimeout(() => {
                setShouldRender(false)
            }, fadeOutDelay + TIME_TO_FADE);
        }

        return () => {
            if (fadeInTimeout) clearTimeout(fadeInTimeout);
            if (fadeOutTimeout) clearTimeout(fadeOutTimeout);
            if (removeTimeout) clearTimeout(removeTimeout);
        };
    }, [startFadeIn, startFadeOut, fadeInDelay, fadeOutDelay]);

    // Fade transition styling
    const elementStyle: React.CSSProperties = {
        opacity: visible ? 1 : 0,
        transition: `opacity 1s ease-in-out`,
    };

    return shouldRender ? <div style={elementStyle}>{children}</div> : null;
}
