import React from "react";
import {useParams} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Join from "../join/join";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Tutorial from "../../shared/tutorial/tutorial";
import Waiting from "../waiting/waiting";
import useWebSocket from "react-use-websocket";
import Playing from "../playing/playing";
import Answer from "../../shared/answer/answer";
import SippingTimePlayer from "../sipping-time/sipping-time";
import WinnerScreenPlayer from "../winner/winner";
import NoConnection from "../../shared/no-connection/no-connection";

export default function PlayerPage({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const {lobbyCode} = useParams();
    const {currentState} = useGameState();

    // Uninitialized or not connected.
    if (!webSocket.readyState || webSocket.readyState === 3)
        return <NoConnection/>

    switch (currentState.player.state) {
        case "Start":
            return <><Join lobbyCode={lobbyCode ?? ""} webSocket={webSocket}/></>;
        case 'Waiting':
            return <><Waiting webSocket={webSocket}/></>;
        case 'Tutorial':
            return <><Tutorial webSocket={webSocket} host={false}/></>;
        case 'Playing':
            return <><Playing webSocket={webSocket}/></>;
        case 'Answer':
            return <><Answer webSocket={webSocket} host={false}/> </>;
        case 'SippingTime':
            return <><SippingTimePlayer webSocket={webSocket}></SippingTimePlayer></>
        case 'Winner':
            return <><WinnerScreenPlayer webSocket={webSocket}></WinnerScreenPlayer></>
        default:
            return <div>Error: Invalid game state</div>;
    }
}
