import {BowbEvent} from "../../../../../common/models";
import React, {useEffect, useState} from "react";
import "./playing.css"
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import useWebSocket from "react-use-websocket";
import Textbox from "../../../components/textbox/textbox";
import CommonBob from "../../../components/bob/bob";
import {EventType} from "../../../events";
import GangMemberChoice from "../../../components/gang-member-choice/gang-member-choice";
import Toaster, {ToastService} from "../../../services/toaster.service";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

export default function Playing({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);

    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket;

    let event: BowbEvent = {type: "none", subType: "none", data: {}};
    useEffect(() => {
        event = lastJsonMessage as BowbEvent;

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.ANSWER_CHOSEN) {
            // Update counter of players that have yet to choose
            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: {
                    ...prevState.currentQuote,
                    playersToAnswer: currentState.currentQuote.playersToAnswer - 1
                }
            }));
        } else if (event?.subType === EventType.SHOW_ANSWER) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {...prevState.host, state: 'Answer'},
                    currentQuote: {...prevState.currentQuote, playersToSip: event?.data.players_to_sip}
                }));
            }, STANDARD_TRANSITION_DELAY);
        } else if (event?.subType === EventType.LEFT_LOBBY) {
            // If player already answered, don't subtract 1 from playersToAnswer. Otherwise, do.
            if (event.data.alreadyAnswered) {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {
                        ...prevState.host,
                        players: prevState.host.players.filter(player => player !== event.data.username)
                    },
                    currentQuote: {
                        ...prevState.currentQuote,
                        playersTotal: prevState.currentQuote.playersTotal - 1,
                        playersToSip: prevState.currentQuote.playersToSip.filter(player => player !== event.data.username)
                    }
                }));
            } else {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {
                        ...prevState.host,
                        players: prevState.host.players.filter(player => player !== event.data.username)
                    },
                    currentQuote: {
                        ...prevState.currentQuote,
                        playersToAnswer: prevState.currentQuote.playersToAnswer - 1,
                        playersTotal: prevState.currentQuote.playersTotal - 1,
                        playersToSip: prevState.currentQuote.playersToSip.filter(player => player !== event.data.username)
                    }
                }));
            }

            ToastService.error(`User ${event.data.username} disconnected and left the lobby.`);
        } else if (event?.subType === EventType.USER_REJOIN) {
            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)

            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: {
                    ...prevState.currentQuote,
                    playersTotal: ++prevState.currentQuote.playersTotal,
                },
                host: {
                    ...prevState.host,
                    players: playerList
                }
            }))

            ToastService.info(`User ${event.data.username} rejoined lobby.`);
        }
    }, [lastJsonMessage]);

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            <h1 className={"player-answer-count"}>
                {currentState.currentQuote.playersToAnswer}
                <br/>
                {currentState.currentQuote.playersToAnswer === 1 ? "answer left" : "answers left"}
            </h1>

            <div className="container">
                <div className={"borp"}>
                    <Textbox text={currentState.currentQuote.question}></Textbox>
                    <img className={"talking-stick"}
                         src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`} alt={"talking stick"}/>
                    <CommonBob></CommonBob>
                </div>
                <div className={"quote-container"}>
                    {currentState.currentQuote.quoteData.imageName === ""
                        ? <Textbox text={currentState.currentQuote.quoteData.quoteText}/>
                        : <img className={"quote-image"}
                               src={`./assets/img/quotes/${currentState.currentQuote.quoteData.imageName}.png`}
                               alt={"quote"}/>
                    }
                </div>
                <GangMemberChoice></GangMemberChoice>
            </div>

            <Toaster/>
        </FadeTransition>
    );
}
