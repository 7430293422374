import {BowbEvent} from "../../../../../common/models";
import React, {useEffect, useState} from "react";
import {EventType} from "../../../events";
import CommonButton from "../../../components/button/button";
import "./start.css"
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import useWebSocket from "react-use-websocket";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

export default function Start({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [lobbyHostCode, setLobbyHostCode] = useState('');
    const [startHosting, setStartHosting] = useState(false);
    const {setCurrentState} = useGameState();

    const {sendJsonMessage, lastJsonMessage} = webSocket;

    let event: BowbEvent = {type: "none", subType: "none", data: {}};
    useEffect(() => {
        event = lastJsonMessage as BowbEvent;
        if (event?.subType === EventType.OPEN_LOBBY && lobbyHostCode === '') {
            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    lobby: {...prevState.lobby, code: event.data.lobby_code},
                    host: {state: "Lobby", players: []},
                    player: {username: "", state: "Start", rejoined: false},
                }));
                setLobbyHostCode(event.data.lobby_code);
            }, STANDARD_TRANSITION_DELAY);
            setStartHosting(true);
        }
    }, [lastJsonMessage]);

    async function startGame() {
        sendJsonMessage(
            {
                type: EventType.LOBBY_EVENT,
                subType: EventType.CREATE_LOBBY,
                data: {}
            })
    }

    return (
        <FadeTransition startFadeOut={startHosting}>
            <div className="yep">
                <h1>Who Should We Cancel?</h1>
                <img src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Judging.gif`} alt="bob sniffing"/>
                <CommonButton action={startGame} text={"Start"}></CommonButton>
            </div>
        </FadeTransition>
    );
}
