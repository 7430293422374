import React, {FC, useEffect, useState} from 'react';
import "./wswc-wrapped.css";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import CommonButton from "../../../components/button/button";
import Textbox from "../../../components/textbox/textbox";
import Stamp from "../../shared/stamp/stamp";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import Balloon from "../../../components/balloon/Balloon";
import WswcWrappedService from "../../../services/wswc-wrapped.service";
import {GeneralScores} from "../../../../../common/models";
import {AuthorOption} from "../../../../../common/types";
import WswcWrappedOverView from "./wswc-wrapped-overview";

interface WswcWrappedProperties {
    webSocket: ReturnType<typeof useWebSocket>;
}

export type WrappedState =
    "Title"         // Start screen
    | "Intro"       // Sentence to kick it off
    | "Connoisseur" // Person who got the most correct
    | "Clueless"    // Person who got the most incorrect
    | "Quickdraw"   // Person that had the fastest average reaction time
    | "KnownAndUnknown" // Two quote authors, one guessed most correctly and one guessed most incorrectly
    | "Overview";      // The general overview, end of WSWC Wrapped

const stateOrder: WrappedState[] = ["Title", "Intro", "Connoisseur", "Clueless", "Quickdraw", "KnownAndUnknown", "Overview"];

let connoisseur: { name: string; score: number } = {name: "", score: 0};
let clueless: { name: string; score: number } = {name: "", score: 0};
let quickdraw: { name: string; score: number } = {name: "", score: Infinity};
let unknownAuthor: { name: AuthorOption; score: number } = {name: "Invalid", score: 0};
let wellKnownAuthor: { name: AuthorOption; score: number } = {name: "Invalid", score: 0};
let totalQuotes: number = 0;

const WswcWrapped: FC<WswcWrappedProperties> = () => {
    const [goToLobby, setGoToLobby] = useState(false);
    const [wrappedState, setWrappedState] = useState<WrappedState>("Title");
    const {currentState, setCurrentState} = useGameState();
    const [show, setShow] = useState(false);
    const [scores, setScores] = useState<GeneralScores>({
        connoisseur,
        clueless,
        quickdraw,
        unknownAuthor,
        wellKnownAuthor,
        totalQuotes
    });

    useEffect(() => {
        console.log(currentState.lobby)
        setScores(WswcWrappedService.calculateGeneralScores(currentState.lobby));
        console.log(scores)
    }, []);

    const nextWrappedState = (state?: WrappedState) => {
        if (state)
            setWrappedState(state);
        else
            setWrappedState((prev) =>
                stateOrder[Math.min(stateOrder.indexOf(prev) + 1, stateOrder.length - 1)]
            );
    };

    const handleBackToLobby = () => {
        setTimeout(() => setCurrentState(prevState => ({
                ...prevState,
                host: {...prevState.host, state: 'Lobby'}
            })
        ), 500)

        setGoToLobby(true);
    }

    const handleStartCarousel = () => {
        const phaseTime = 10000;
        const hideTime = 1000;
        const showTime = 3500;
        const totalPhases = 4;

        // Start the initial transition
        nextWrappedState();
        setTimeout(() => setShow(true), showTime);
        setTimeout(() => setShow(false), phaseTime / 2); // Half-time for intro

        let phase = 0;
        const updatePhase = () => {
            if (phase >= totalPhases) {
                if (wrappedState !== "Overview")
                    setTimeout(() => nextWrappedState(), 20000);

                return;
            }

            setTimeout(() => setShow(false), 1800);
            setTimeout(() => {
                nextWrappedState();
                setTimeout(() => setShow(true), showTime);
            }, hideTime);

            phase++;
        };

        // Start the next phases at an interval after the initial phase
        setTimeout(() => {
            updatePhase(); // Manually trigger the second phase to sync the interval
            const interval = setInterval(updatePhase, phaseTime);
            setTimeout(() => clearInterval(interval), phaseTime * totalPhases); // Clear interval after all phases
        }, phaseTime / 2);
    };

    const handleGoToOverview = () => setWrappedState("Overview");

    return (
        <FadeTransition startFadeIn={true} startFadeOut={goToLobby}>
            {/* Stage 0 - Title */}
            <FadeTransition startFadeIn={wrappedState == "Title"}
                            startFadeOut={wrappedState == "Intro" || wrappedState == "Overview"}>
                <div className="wrapped-container">
                    <span className={"wrapped-stamp"}>
                        <Stamp delay={1000} stampType="Wrapped"></Stamp>
                    </span>
                    <h1 className={"wswc-title"}>Who Should We Cancel</h1>
                </div>

                <FadeTransition startFadeIn={wrappedState == "Title"} fadeInDelay={1000} startAlreadyRendered={true}>
                    <div className="wrapped-container">
                        <Textbox text="This will be more accurate after a few rounds!"></Textbox>
                        <div className={"wrapped-intro-buttons"}>
                            <CommonButton text={"<Lobby"} action={handleBackToLobby}></CommonButton>
                            <CommonButton text={"Overview"} action={handleGoToOverview}></CommonButton>
                            <CommonButton text={"Start>"} action={handleStartCarousel}></CommonButton>
                        </div>
                    </div>
                </FadeTransition>
            </FadeTransition>

            {/* Stage 1 - Intro */}
            <FadeTransition startFadeIn={wrappedState == "Intro"}
                            startFadeOut={wrappedState == "Connoisseur"}
                            fadeInDelay={1000}>
                <div className="wrapped-container">
                    <h1>Let's see what the heck happened here..</h1>
                </div>
            </FadeTransition>

            {/* Stage 2 - Highest score */}
            <FadeTransition startFadeIn={wrappedState == "Connoisseur"}
                            startFadeOut={wrappedState == "Clueless"}
                            fadeInDelay={1000}>
                <div className="wrapped-container">
                    <h1 className={show ? "moveable category-title up" : "moveable category-title"}>Connoisseur</h1>
                    <span className={show ? "moveable category-description under-up" : "moveable category-description"}>
                        <p>This person got the most right!</p>
                    </span>
                    <span className={"category-person"}>
                        <Stamp stampType={"Text"} text={scores!.connoisseur.name} delay={3500}/>
                    </span>
                    <span className={"category-details"}>
                        <FadeTransition fadeInDelay={4500}>
                            <Textbox
                                text={`Answered ${scores!.connoisseur.score} of ${scores!.totalQuotes} quotes correctly`}/>
                        </FadeTransition>
                    </span>
                </div>
            </FadeTransition>

            {/* Stage 3 - Lowest score */}
            {/* TODO #10, this category should be changed based on whether it's a sipping game or not. */}
            <FadeTransition startFadeIn={wrappedState == "Clueless"}
                            startFadeOut={wrappedState == "Quickdraw"}
                            fadeInDelay={1500}>
                <div className="wrapped-container">
                    <h1 className={show ? "moveable category-title up" : "moveable category-title"}>Drunkard</h1>
                    <span className={show ? "moveable category-description under-up" : "moveable category-description"}>
                        <p>This person.. had a LOT of sips</p>
                    </span>
                    <span className={"category-person"}>
                        <Stamp stampType={"Text"} text={scores!.clueless.name} delay={3500}/>
                    </span>
                    <span className={"category-details"}>
                        <FadeTransition fadeInDelay={4500}>
                            <Textbox
                                text={`Had to take ${scores!.clueless.score} ${scores!.clueless.score == 1 ? "sip" : "sips"}`}/>
                        </FadeTransition>
                    </span>
                </div>
            </FadeTransition>

            {/* Stage 4 - Highest average reaction time */}
            <FadeTransition startFadeIn={wrappedState == "Quickdraw"}
                            startFadeOut={wrappedState == "KnownAndUnknown"}
                            fadeInDelay={1500}>
                <div className="wrapped-container">
                    <h1 className={show ? "moveable category-title up" : "moveable category-title"}>Quickdraw</h1>
                    <span className={show ? "moveable category-description under-up" : "moveable category-description"}>
                        <p>The fastest average reaction time.. <br/> Alright cowboy, calm down!</p>
                    </span>
                    <span className={"category-person"}>
                        <Stamp stampType={"Text"} text={scores!.quickdraw.name} delay={3500}/>
                    </span>
                    <span className={"category-details"}>
                        <FadeTransition fadeInDelay={4500}>
                            <Textbox text={`Answered with an average of ${scores!.quickdraw.score} seconds`}/>
                        </FadeTransition>
                    </span>
                </div>
            </FadeTransition>

            {/* Stage 5 - Quote authors guessed (in)correctly most often */}
            <FadeTransition startFadeIn={wrappedState == "KnownAndUnknown"}
                            startFadeOut={wrappedState == "Overview"}
                            fadeInDelay={1500}>
                <div className="wrapped-container">
                    <h1 className={show ? "moveable category-title up" : "moveable category-title"}>Perpetrators</h1>
                    <div className="wrapped-authors">
                        <div className="person">
                            <FadeTransition fadeInDelay={4000} startAlreadyRendered={true}>
                                <p>This person has fooled you all! <br/> They were judged incorrectly the most.</p>
                            </FadeTransition>
                            <FadeTransition fadeInDelay={6000}>
                                <Balloon imageName={`gang-members/${scores.unknownAuthor.name}.jpg`}
                                         floatOut={wrappedState == "Quickdraw"}/>
                            </FadeTransition>
                            <Stamp stampType={"Cancelled"} delay={20000}/>
                        </div>
                        <div className="person">
                            <FadeTransition fadeInDelay={12000} startAlreadyRendered={true}>
                                <p>This person is infamous! <br/> They were judged correctly most often.</p>
                            </FadeTransition>
                            <FadeTransition fadeInDelay={14000}>
                                <Balloon imageName={`gang-members/${scores.wellKnownAuthor.name}.jpg`}
                                         floatOut={wrappedState == "Quickdraw"}/>
                            </FadeTransition>
                            <Stamp stampType={"Cancelled"} delay={20000}/>
                        </div>
                    </div>
                </div>
            </FadeTransition>

            {/* Stage 6 - General Overview */}
            <FadeTransition startFadeIn={wrappedState == "Overview"}
                            fadeInDelay={1500}
                            startFadeOut={wrappedState != "Overview"}>
                <WswcWrappedOverView scores={scores}
                                     onRestart={() => nextWrappedState("Title")}
                                     toLobby={handleBackToLobby}
                ></WswcWrappedOverView>
            </FadeTransition>
        </FadeTransition>
    );
};

export default WswcWrapped;