import React, {useEffect, useState} from 'react';
import "./no-connection.css";
import CommonBob, {BobType} from "../../../components/bob/bob";
import Textbox from "../../../components/textbox/textbox";
import FadeTransition from "../../../components/fade-element/FadeTransition";

const NoConnection = () => {
    const [startFade] = useState(false);
    const [timeoutCount, setTimeoutCount] = useState(50);
    const [dotsCount, setDotsCount] = useState(1);

    useEffect(() => {
        const counter = setInterval(() => {
            setTimeoutCount(prevCount => prevCount - 1);
        }, 1000);

        const dotCounter = setInterval(() => {
            setDotsCount(prevCount => prevCount % 3 == 0 ? 1 : (prevCount + 1));
        }, 750);

        return () => {
            clearInterval(counter);
            clearInterval(dotCounter);
        };
    }, []);

    useEffect(() => {
        if (timeoutCount <= 0)
            window.location.reload();
    }, [timeoutCount]);

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>

            <div className={"connection-container"}>
                <CommonBob bobType={BobType.Judging}></CommonBob>
                <div className={"image-div"}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick.png`} className={"talking-stick"}
                         alt={"pic of talking stick"}/>
                </div>
                <div className={"text-container"}>
                    <h2>Waking the server up{".".repeat(dotsCount)}</h2>
                    <p>
                        There is currently no connection to the server. Try again in about a minute. {"\n\n"}
                        If you keep seeing this screen, the server may be offline.
                    </p>
                </div>
                <Textbox text={`Retrying in ${timeoutCount}..`}></Textbox>
            </div>
        </FadeTransition>
    );
};

export default NoConnection;
