import React from 'react';
import './confirmation-modal.css';
import Textbox from "../textbox/textbox";
import CommonButton from "../button/button"; // Import styles

interface ConfirmationModalProps {
    question: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({question, onConfirm, onCancel}) => {
    return (
        <div className="modal-container">
            <Textbox text={question}/>
            <div className="modal-buttons">
                <CommonButton action={onCancel} text="Back"/>
                <CommonButton action={onConfirm} text="Yes"/>
            </div>
        </div>
    );
};

export default ConfirmationModal;
