import React, {FC} from 'react';
import "./stamp.css";
import StampElement from "../../../components/stamp-transition/stamp-transition";

interface CommonStampProperties {
    stampType: "Cancelled" | "Wrapped" |  "Text" | "Image";
    delay: number;
    imageName?: string;
    text?: string;
}

const Stamp: FC<CommonStampProperties> = ({stampType, delay, imageName, text}) => {
    return (
        <StampElement delay={delay}>
            <div className={"stamp"}>
                {stampType === "Text"
                    ? <h1 className={"stamp-text"}>{text}</h1>
                    : <img className={"stamp-image"} src={getImageSource(stampType, imageName)} alt={"Stamp"}/>
                }
            </div>
        </StampElement>
    );
};

export default Stamp;

function getImageSource(stampType: string, imageName?: string) {
    switch (stampType) {
        case 'Cancelled':
            return `${process.env.PUBLIC_URL}/assets/img/cancelled.png`;
        case 'Wrapped':
            return `${process.env.PUBLIC_URL}/assets/img/wswc-wrapped.png`;
        case 'Image':
            return `${process.env.PUBLIC_URL}/assets/img/${imageName}`;
        default:
            return `${process.env.PUBLIC_URL}/assets/img/cancelled.png`;
    }
}