import React, {useEffect, useState} from "react";
import "./Balloon.css";

interface BalloonProps {
    text?: string;
    imageName?: string;
    floatOut?: boolean;
}

const Balloon: React.FC<BalloonProps> = ({text, imageName, floatOut = false}) => {
    const [startLeft, setStartLeft] = useState<boolean>(true);
    const [animationDuration, setAnimationDuration] = useState<number>(6);
    const [floatingOut, setFloatingOut] = useState<boolean>(false);

    useEffect(() => {
        setStartLeft(Math.random() > 0.5);
        setAnimationDuration(4 + Math.random() * 2);

        if (floatOut) {
            setFloatingOut(true);
        }
    }, [floatOut]);

    return (
        <div
            className={`balloon ${startLeft ? "from-left" : "from-right"} ${floatingOut ? "float-out" : ""}`}
            style={{animationDuration: `${animationDuration}s`}}>
            {imageName
                ? (<img src={`${process.env.PUBLIC_URL}/assets/img/${imageName}`} alt="balloon content"/>)
                : (<p className={"balloon-text"}>{text}</p>)
            }

            <svg
                className="balloon-cord"
                width="10"
                height="150"
                viewBox="0 0 10 150"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5,0 Q10,20 5,40 T5,80 T5,120 T5,150"
                    stroke="gray"
                    strokeWidth="2"
                    fill="transparent"
                    strokeLinecap="round"
                    className="wiggle"
                />
            </svg>
        </div>
    );
};

export default Balloon;
