import React, {FC} from 'react';
import "./textbox.css";

interface CommonTextboxProperties {
    text: string;
}

const Textbox: FC<CommonTextboxProperties> = ({text}) => {
    return (
        <>
            <div className={"fancy-textbox"}>{text}</div>
        </>
    );
};

export default Textbox;
