import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./winner.css";
import Textbox from "../../../components/textbox/textbox";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../../../common/models";
import {EventType} from "../../../events";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

export default function WinnerScreenPlayer({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket
    const [startFadeConfetti, setStartFadeConfetti] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStartFadeConfetti(true);
        }, 3000);
    });

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data?.lobby_code === currentState.lobby.code
        if (!validLobby) return;

        if (event?.subType === EventType.TO_LOBBY) {
            setStartFade(true);
            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    player: {...prevState.player, state: "Waiting"}
                }));
            }, STANDARD_TRANSITION_DELAY);
        }
    }, [lastJsonMessage]);

    if (currentState.lobby.winner.includes(currentState.player.username))
        return (
            <FadeTransition startFadeOut={startFade} startFadeIn={true}>
                <div className={"noSip"}>
                    <div className={"borp"}>
                        <Textbox text={"Congratulations, you won! 🍺🥕"}></Textbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking stick"}/>
                        <img className={"loading-gif"}
                             src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                             alt={"Bob hopping indefinitely"}/>
                    </div>
                    <FadeTransition startFadeOut={startFadeConfetti}>
                        <img className={"fnaf-confetti"}
                             src={`${process.env.PUBLIC_URL}/assets/img/fnaf-confetti.gif`} alt={"fnaf confetti"}/>
                    </FadeTransition>
                </div>
            </FadeTransition>
        );
    else
        return (
            <FadeTransition startFadeOut={startFade} startFadeIn={true}>
                <div className={"noSip"}>
                    <div className={"borp"}>
                        <Textbox text={"Bummer! Better luck next time 🥕"}></Textbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`}
                             alt={"talking stick"}/>
                        <img className={"loading-gif"}
                             src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                             alt={"Bob hopping indefinitely"}/>
                    </div>
                </div>
            </FadeTransition>
        );
}
