import React, {FC} from "react";
import "./ScoreboardTable.css";
import {Lobby} from "../../../../common/models";

interface ScoreboardTableProps {
    lobby: Lobby;
}

const ScoreboardTable: FC<ScoreboardTableProps> = ({lobby}) => {
    // Sort by currentCorrectScore, or when that's the same, by time to answer.
    const sortedPlayers = Object.entries(lobby.score.playerScores)
        .map(([name, playerScore]) => ({...playerScore, name}))
        .sort((a, b) =>
            (a.currentCorrectScore !== b.currentCorrectScore)
                ? b.currentCorrectScore - a.currentCorrectScore
                : a.currentTimeToAnswer - b.currentTimeToAnswer
        );
    // Calculate the time to answer for each player, showing only increments after the initial winner time
    const initialWinnerTime = sortedPlayers[0].currentTimeToAnswer;
    const relativeTimes = sortedPlayers.map((player, index) =>
        (index === 0)
            ? initialWinnerTime
            : player.currentTimeToAnswer - initialWinnerTime);

    return (
        <div className="table-container">
            <table className="player-table">
                <thead>
                <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Correct</th>
                    <th>Sips taken</th>
                    <th>Rounds Won</th>
                    <th>Time to winner's answer</th>
                </tr>
                </thead>
                <tbody>
                {sortedPlayers.map((player, index) => (
                    <tr key={player.name}>
                        <td>{index + 1}</td>
                        <td>{player.name}</td>
                        <td>{player.currentCorrectScore}</td>
                        <td>{player.currentIncorrectScore}</td>
                        <td>{player.totalRoundsWon}</td>
                        <td>
                            {player.currentTimeToAnswer / 1000}s
                            {index > 0 &&
                                <span>
                                     {" "} ({(relativeTimes[index] >= 0) ? '+' : ''}{(relativeTimes[index] / 1000).toFixed(3)}s)
                                </span>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ScoreboardTable;
