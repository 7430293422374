import React, { FC, useEffect, useState } from 'react';
import "./music-control.css";
import AudioService from "../../services/audio.service";

interface MusicControlProps {
    state: 'lobby' | 'playing';
}

const MusicControl: FC<MusicControlProps> = ({ state }) => {
    const [muted, setMuted] = useState(true);

    useEffect(() => {
        const playMusic = async () => {
            if (muted) {
                await AudioService.stopMusic();
                return;
            }

            if (state === 'lobby') {
                await AudioService.playMusic("soundtrack/WhoShouldWeCancel_Lobby.mp3");
            } else {
                await AudioService.playMusic("soundtrack/WhoShouldWeCancel_Playing.mp3");
            }

            AudioService.setMusicVolume(1);
            AudioService.mute(false);
        };

        void playMusic();

        return () => {
            void AudioService.stopMusic();
        };
    }, [state, muted]);

    const toggleMute = () => {
        setMuted((prev) => {
            const newMuted = !prev;
            AudioService.mute(newMuted);
            AudioService.setMusicVolume(newMuted ? 0 : 1);
            return newMuted;
        });
    };

    return (
        <div className="music-container">
            <button
                className={`mute-toggle ${muted ? 'muted' : 'unmuted'}`}
                onClick={toggleMute}
            >
                <span className="icon">{muted ? '🔇' : '🔊'}</span>
            </button>
        </div>
    );
};

export default MusicControl;
