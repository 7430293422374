import useWebSocket from "react-use-websocket";
import {BowbEvent} from "../../../../../common/models";
import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import {EventType} from "../../../events";
import CommonQr from "../../../components/qr/qr-code";
import CommonButton from "../../../components/button/button";
import "./lobby.css"
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import Toaster, {ToastService} from "../../../services/toaster.service";
import Settings from "./settings";
import CommonBob, {BobType} from "../../../components/bob/bob";
import ConfirmationModal from "../../../components/confirmation-modal/confirmation-modal";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";


export default function Lobby({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const {currentState, setCurrentState} = useGameState();
    const [goNext, setGoNext] = useState(false);
    const {lastJsonMessage, sendJsonMessage} = webSocket
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [wrappedModalOpen, setWrappedModalOpen] = useState(false);

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.JOINED_LOBBY_FAILURE) {
            ToastService.error(event?.data.message);
        } else if (event?.subType === EventType.LEFT_LOBBY) {
            ToastService.info(event?.data.message);

            setCurrentState(prevState => ({
                ...prevState,
                host: {
                    ...prevState.host,
                    players: [...currentState.host.players].filter(playerName => playerName !== event?.data.username)
                }
            }));
        } else if (event?.subType === EventType.USER_REJOIN) {
            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)

            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: {...prevState.currentQuote, playersTotal: ++prevState.currentQuote.playersTotal},
                host: {...prevState.host, players: playerList}
            }))

            ToastService.info(`User ${event.data.username} rejoined lobby.`);
        } else if (event?.subType === EventType.NO_QUOTES_LEFT) {
            ToastService.error("There are no more quotes left!");
            ToastService.info("Tip! Say something out of pocket now, to continue.");
        } else if (event?.subType === EventType.START_GAME) {
            setGoNext(true)

            setTimeout(() =>
                setCurrentState(prevState => ({
                        ...prevState,
                        host: {...prevState.host, state: 'Tutorial'}
                    })
                ),
                STANDARD_TRANSITION_DELAY
            )

        }
    }, [lastJsonMessage]);

    const handleStartJudging = () => {
        if (currentState.host.players.length == 0) {
            ToastService.error("Are you really trying to start this game without people?");
            return;
        }

        setTimeout(() => {
            sendJsonMessage(
                {
                    type: EventType.GAME_EVENT,
                    subType: EventType.START_GAME,
                    data: {
                        lobby_code: currentState.lobby.code
                    }
                });
        }, 200);
    }

    const handleStartWrapped = () => {
        setTimeout(() => setCurrentState(prevState => ({
                ...prevState,
                host: {...prevState.host, state: 'Wrapped'}
            })
        ), STANDARD_TRANSITION_DELAY)

        setWrappedModalOpen(false)

        setGoNext(true);
    }

    return (
        <>
            <FadeTransition startFadeOut={goNext}>
                <div className={"lobby-container"}>
                    <div className={"code-and-player-container"}>
                        <div className={"qr-wrapper"}>
                            <CommonQr lobbyCode={currentState.lobby.code}></CommonQr>
                        </div>
                        <div className={"judge-container"}>
                            <h2>Judges:</h2>
                            <p className={"judge-names"}>
                                {currentState.host.players.map((player, index) => (
                                    <span className={"player-name"} key={index}>{player}</span>
                                ))}
                            </p>
                            <div className={"bob-button"}>
                                <CommonButton action={handleStartJudging} text={"Start judging"}></CommonButton>
                            </div>
                        </div>
                    </div>
                    <div className={"icon-link-container"}>
                        <span className={"settings"} onClick={() => setSettingsModalOpen(true)}>⚙️</span>
                        <p className={"lobby-link"}>https://wswc.studiostoy.nl/{currentState.lobby.code}
                            <img
                                className={"sniffing-bob"}
                                src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                                alt="bob sniffing"/></p>
                        {
                            currentState.lobby.currentRound > 0 &&
                            <div className={"wswc-wrapped-container"} onClick={() => setWrappedModalOpen(true)}>
                                <CommonBob bobType={BobType.Hopping}></CommonBob>
                            </div>
                        }
                    </div>
                </div>
            </FadeTransition>

            <Modal
                closeTimeoutMS={300}
                isOpen={settingsModalOpen}
                onRequestClose={() => setSettingsModalOpen(false)}
                contentLabel="Settings Modal"
                className="large-modal-content"
                overlayClassName="large-modal-overlay"
            >
                <Settings
                    webSocket={webSocket}
                    currentState={currentState}
                    setModalIsOpen={setSettingsModalOpen}
                />
            </Modal>

            <Modal
                closeTimeoutMS={300}
                isOpen={wrappedModalOpen}
                onRequestClose={() => setWrappedModalOpen(false)}
                contentLabel="Confirmation Modal"
                className="small-modal-content"
                overlayClassName="small-modal-overlay"
            >
                <ConfirmationModal
                    question="View your WSWC Wrapped?"
                    onConfirm={handleStartWrapped}
                    onCancel={() => setWrappedModalOpen(false)}
                />
            </Modal>

            <Toaster/>
        </>
    );
}
