import useWebSocket from "react-use-websocket";
import CommonButton from "../../../components/button/button";
import CommonCheckbox from "../../../components/checkbox/checkbox";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {EventType} from "../../../events";
import {GameState} from "../../../hooks/use-game-state/states";
import {BowbEvent} from "../../../../../common/models";
import {ToastService} from "../../../services/toaster.service";
import "./settings.css"
import Textbox from "../../../components/textbox/textbox";


const STANDARD_SKIP_PERCENTAGE = 50;

export default function Settings({webSocket, currentState, setModalIsOpen}: {
    webSocket: ReturnType<typeof useWebSocket>;
    currentState: GameState;
    setModalIsOpen: (value: boolean) => void;
}) {
    const {sendJsonMessage, lastJsonMessage} = webSocket;
    const [skipPercentageOfQuotes, setSkipPercentageOfQuotes] = useState(false);
    const [skippedQuotesText, setQuoteAmountText] = useState('');
    const [maxRounds, setMaxRounds] = useState(10);

    useEffect(() => {
        requestSettings();
    }, []);

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.SHOW_SETTINGS) {
            handleShowSettings(event.data.max_rounds, event.data.used_quotes_amount, event.data.total_quotes_amount, event.data.skipped_quotes_amount, event.data.skipped_quotes_percentage)
        } else if (event?.subType === EventType.SKIP_QUOTES_PERCENTAGE) {
            handleUsedQuotesText(event.data.used_quotes_amount, event.data.total_quotes_amount, event.data.skipped_quotes_amount)
        } else if (event?.subType === EventType.IMPORT_USED_QUOTES) {
            ToastService.success(`Successfully imported ${event.data.amount} previously used quotes! These will be skipped.`);
            requestSettings();
        } else if (event?.subType === EventType.EXPORT_USED_QUOTES) {
            handleUsedQuotesDownload(event?.data)
        }
    }, [lastJsonMessage]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUsedQuotesExport = () => {
        sendJsonMessage({
            type: EventType.SETTINGS_EVENT,
            subType: EventType.EXPORT_USED_QUOTES,
            data: {
                lobby_code: currentState.lobby.code,
            },
        });
    };

    const handleSkipQuotesPercentage = (skip: boolean) => {
        sendJsonMessage({
            type: EventType.SETTINGS_EVENT,
            subType: EventType.SKIP_QUOTES_PERCENTAGE,
            data: {
                lobby_code: currentState.lobby.code,
                shouldSkipQuotesPercentage: skip,
            },
        });

        setSkipPercentageOfQuotes(skip);
        if (!skip) ToastService.info(`Restored used quotes and will not skip any.`);
    };

    const handleSetMaxRounds = (maxRounds: number) => {
        sendJsonMessage({
            type: EventType.SETTINGS_EVENT,
            subType: EventType.SET_MAX_ROUNDS,
            data: {
                lobby_code: currentState.lobby.code,
                max_rounds: maxRounds,
            },
        });

        setMaxRounds(maxRounds);
    };

    const requestSettings = () => {
        sendJsonMessage(
            {
                type: EventType.SETTINGS_EVENT,
                subType: EventType.SHOW_SETTINGS,
                data: {
                    lobby_code: currentState.lobby.code,
                }
            })
    }

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        handleSetMaxRounds(Number(event.target.value));
    };

    const handleUsedQuotesImport = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target?.result as string) as { used_quotes: [] };
                if (jsonData.used_quotes && Array.isArray(jsonData.used_quotes)) {
                    sendJsonMessage({
                        type: EventType.GAME_EVENT,
                        subType: EventType.IMPORT_USED_QUOTES,
                        data: {
                            lobby_code: currentState.lobby.code,
                            used_quotes: jsonData.used_quotes,
                        },
                    });
                } else {
                    console.error("Invalid JSON format: 'totalUsedQuotes' is not an array.");
                }
            } catch (error) {
                console.error("Error parsing JSON file: ", error);
            }
        };

        reader.readAsText(file);
    };

    const handleUsedQuotesDownload = (usedQuotesJson: { used_quotes: [] }) => {
        if (usedQuotesJson.used_quotes.length === 0) {
            ToastService.info("There are no used quotes yet!");
            return;
        }

        const usedQuotesJsonString = JSON.stringify(usedQuotesJson, null, 2);
        const dataUrl = `data:text/json;charset=utf-8,${encodeURIComponent(usedQuotesJsonString)}`;
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `usedQuotes-${new Date().toLocaleDateString()}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        ToastService.success("Exported used quotes!");
    }

    const handleShowSettings = (max_rounds: number, usedQuotes: number, totalQuotesAmount: number, skippedQuotesAmount: number, skipQuotesPercentage: number) => {
        handleUsedQuotesText(usedQuotes, totalQuotesAmount, skippedQuotesAmount);
        setSkipPercentageOfQuotes(skipQuotesPercentage > 0)
        setMaxRounds(max_rounds);
    }

    const handleUsedQuotesText = (usedQuotes: number, totalQuotesAmount: number, skippedQuotesAmount: number) => {
        let text = `Used ${usedQuotes} of ${totalQuotesAmount} quotes`

        if (skippedQuotesAmount > 0)
             text = `Used ${usedQuotes} of ${totalQuotesAmount} quotes (excludes ${skippedQuotesAmount} skipped)`

        setQuoteAmountText(text);
    }

    return (
        <div className={"modal-content"}>
            <div className={"header-content"}>
                <h2>Settings</h2>
                <a href="https://studiostoy.nl">© Studio Stoy</a>
            </div>
            <div className={"body-content"}>
                <div className={"settings-view"}>
                    <div className="dropdown">
                        <span>Amount of rounds:</span>
                        <select value={maxRounds} onChange={handleDropdownChange}>
                            {[1, 3, 5, 10, 25, 50, 75, 100].map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={"import"}>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleUsedQuotesImport}
                            accept=".json"
                            style={{display: "none"}}
                        />
                        <CommonButton
                            text="Import previously used quotes"
                            action={() => fileInputRef?.current?.click()}
                        />
                    </div>

                    <div className={"export"}>
                        <CommonButton
                            text="Export used quotes to save progress"
                            action={handleUsedQuotesExport}
                        />
                    </div>

                    <CommonCheckbox
                        checked={skipPercentageOfQuotes}
                        onChange={() => handleSkipQuotesPercentage(!skipPercentageOfQuotes)}
                        label={`Skip some older quotes (${STANDARD_SKIP_PERCENTAGE}%)`}
                    />
                </div>
                <div className={"info-view"}>
                    <Textbox text={skippedQuotesText}></Textbox>
                </div>
            </div>
            <div className={"footer-content"}>
                <div className={"close-settings-button"}>
                    <CommonButton text={"Go back"} action={() => setModalIsOpen(false)}/>
                </div>
                <a href={`https://wswc.studiostoy.nl/${currentState.lobby.code}`} className={"lobby-settings-link"}>
                    https://wswc.studiostoy.nl/{currentState.lobby.code}
                    <img
                        className={"sniffing-bob"} src={`${process.env.PUBLIC_URL}/assets/img/bob/bob-Sniffing.gif`}
                        alt="bob sniffing"/>
                </a>
            </div>
        </div>
    );
}
