import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./playing.css";
import useWebSocket from "react-use-websocket";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../../../common/models";
import {EventType} from "../../../events";
import Textbox from "../../../components/textbox/textbox";
import GangMemberChoice from "../../../components/gang-member-choice/gang-member-choice";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

export default function Playing({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);
    const [hasChosen, setHasChosen] = useState(false);
    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage, sendJsonMessage} = webSocket

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.ANSWER_CHOSEN) {
            setHasChosen(true);
        } else if (event?.subType === EventType.SHOW_ANSWER) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: {...prevState.currentQuote, playersToSip: event.data.players_to_sip},
                    player: {...prevState.player, state: 'Answer'}
                }));
            }, STANDARD_TRANSITION_DELAY);
        } else if (event?.subType === EventType.WAIT_ONE_ROUND) {
            setCurrentState(prevState => ({
                ...prevState,
                player: {...prevState.player, state: "Waiting", rejoined: true}
            }));
        }
    }, [lastJsonMessage]);

    function chooseAnswer(answer: string) {
        sendJsonMessage({
            type: EventType.GAME_EVENT, subType: EventType.CHOOSE_ANSWER, data: {
                lobby_code: currentState.lobby.code,
                answer: answer,
                players_to_answer: currentState.currentQuote.playersToAnswer
            }
        })
    }

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            {hasChosen
                ? <div className={"player-wait-holder"}>
                    <Textbox text={`You have cast your judgment.`}></Textbox>
                    <img className={"loading-gif"}
                         src={"./assets/img/bob/bob-Hopping.gif"}
                         alt={"Bob hopping indefinitely"}/>
                </div>
                : <div className={"choice-container"}>
                    <GangMemberChoice action={chooseAnswer}></GangMemberChoice>
                </div>
            }
        </FadeTransition>
    );
}
