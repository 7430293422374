import React, {FC, useEffect, useState} from 'react';
import "./tutorial.css";
import CommonBob from "../../../components/bob/bob";
import useWebSocket from "react-use-websocket";
import {EventType} from "../../../events";
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import {BowbEvent} from "../../../../../common/models";
import CommonButton from "../../../components/button/button";
import ProgressBar from "@ramonak/react-progress-bar";
import FadeTransition from "../../../components/fade-element/FadeTransition";
import {STANDARD_TRANSITION_DELAY} from "../../../models/consts";

interface CommonTutorialProperties {
    webSocket: ReturnType<typeof useWebSocket>;
    host: boolean;
}

const Tutorial: FC<CommonTutorialProperties> = ({webSocket, host}: {
    webSocket: ReturnType<typeof useWebSocket>,
    host: boolean
}) => {
    const [startFade, setStartFade] = useState(false);
    const [count, setCount] = useState(0);
    const [timer, setTimer] = useState(0);
    const {currentState, setCurrentState} = useGameState();
    const {sendJsonMessage, lastJsonMessage} = webSocket;

    let counter: NodeJS.Timer | undefined;
    counter = setInterval(() => {
        setCount(count + 1);
    }, 1000)

    useEffect(() => {
        if (host && !timer) {
            setTimer(window.setTimeout(() => {
                sendJsonMessage(
                    {
                        type: EventType.GAME_EVENT,
                        subType: EventType.NEXT_QUOTE,
                        data: {lobby_code: currentState.lobby.code}
                    });
            }, 11000));
        }
    }, [host]);

    useEffect(() => {
        const event: BowbEvent = lastJsonMessage as BowbEvent

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (!validLobby) return;

        if (event?.subType === EventType.NEXT_QUOTE_FOUND) {
            setStartFade(true);
            clearInterval(counter);
            clearTimeout(timer);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    currentQuote: event.data.currentQuote,
                    host: {...prevState.host, state: "Playing"},
                    player: {...prevState.player, state: "Playing"}
                }));
            }, STANDARD_TRANSITION_DELAY);
        }
    }, [counter, currentState.lobby.code, setCurrentState, timer, lastJsonMessage]);

    return (
        <FadeTransition startFadeOut={startFade} startFadeIn={true}>
            <div className={"tutorial-container"}>
                <CommonBob></CommonBob>
                <div className={"image-div"}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick.png`} className={"talking-stick"}
                         alt={"pic of talking stick"}/>
                </div>
                <div className={"text-container"}>
                    <p className={"tutorial-text"}>It is time for you to judge these criminals.
                        They have all said things that cannot leave this courtroom.
                        So the question is,
                        Who should we cancel?</p>
                </div>

                <ProgressBar completed={count}
                             maxCompleted={10}
                             barContainerClassName="bar-container"
                             customLabel={" "}
                             className="bar-wrapper"
                             bgColor={"var(--wswc-red)"}
                ></ProgressBar>
            </div>

            {host ?
                <div className={"botton"}>
                    <CommonButton text={"Skip"} action={skip}></CommonButton>
                </div>
                : <></>
            }
        </FadeTransition>
    );

    function skip() {
        clearTimeout(timer);
        clearInterval(counter);
        sendJsonMessage({
            type: EventType.GAME_EVENT,
            subType: EventType.NEXT_QUOTE,
            data: {lobby_code: currentState.lobby.code}
        });
    }
};

export default Tutorial;
